import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, MenuItem, Select, Skeleton, Typography } from '@mui/material';
import { Reply } from '@mui/icons-material';
import moment from 'moment';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';

import ButtonGroupConta from '../ButtonGroupConta';
import formatMoney from '../../../../utils/formatMoneyBRL';
import { ReloadButtonStyle } from '../DigitalAccountBalaceContainer/style';
import { FiRefreshCw } from 'react-icons/fi';
import Loading from '../../../../components/Loader/Loading';
import ModalEnviarParaBaas from '../../../../components/Modais/ModalEnviarParaBaas/ModalEnviarParaBaas';

export default function AccordionInfoBankAccount({
  informacoesPessoais,
  user,
  setModalSenhaUnica,
  modalSenhaUnica,
  infoPessoaisRedux,
  setOperation,
  accountVisible,
  setAccountVisible,
  searchWithFilter,
}) {

  const [expanded, setExpanded] = useState(true);
  const { loading_coban } = useSelector((state) => state.coban);
  const { loading } = useSelector((state) => state.user);

  const {
    user: { CONTA_BACEN }, saldo
  } = useSelector((state) => state.user);

  const [modalBaas, setModalBaas] = useState({
    open: false,
    header: '',
    body: '',
    type: 'account',
    requestBody: {}
  });

  function handleModalBaasAccount() {
    setModalBaas({
      open: true,
      header: 'Disparo de conta',
      body: 'Deseja enviar para o microserviço terceiros?',
      type: 'account',
      requestBody: {
        CONTA: accountVisible.CONTA,
        CPFCNPJ_USUARIO: null
      }
    });
  }

  return (
    <>
      <Accordion expanded={expanded}>
        <AccordionSummary>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px' }}>
              Conta
            </Typography>
            <Box sx={{ mr: 5 }}>
              <ButtonGroupConta
                user={user}
                setModalSenhaUnica={setModalSenhaUnica}
                modalSenhaUnica={modalSenhaUnica}
                infoPessoaisRedux={infoPessoaisRedux}
                setOperation={setOperation}
                alteraCliente={informacoesPessoais.alteraCliente}
              />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {!loading_coban ? (
            <Card>
              <CardBody>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ width: '100%' }}>
                    <Row>
                      <Col xs={5}>
                        <Box mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={styles.textForm}>
                            <b>Conta: </b>{' '}
                          </Typography>
                          {accountVisible && (
                            <>
                              <Select
                                defaultValue={accountVisible.UUID}
                                size='small'
                                onChange={({ target: { value } }) => {
                                  const account = CONTA_BACEN.filter((account) => account.UUID === value);
                                  setAccountVisible(account[0]);
                                }}
                              >
                                {CONTA_BACEN &&
                                  CONTA_BACEN.length > 0 &&
                                  CONTA_BACEN.map((account) => (
                                    <MenuItem
                                      key={account.UUID}
                                      value={account.UUID}
                                    >{`${account.CONTA}-${account.DIGITO}`}</MenuItem>
                                  ))}
                              </Select>
                              {informacoesPessoais.id_tipo_usuario !== 1 &&
                                accountVisible &&
                                accountVisible.CONTA &&
                                !accountVisible.DT_ENCERRAMENTO && (
                                  <Reply style={{ cursor: 'pointer', marginLeft: '0.5rem' }} onClick={handleModalBaasAccount} />
                                )}
                            </>
                          )}
                        </Box>
                      </Col>
                      <Col xs={5}>
                        <Box mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={styles.textForm}>
                            <b>Saldo Parceiro:</b>
                          </Typography>
                          {loading ? (
                            <Skeleton animation="wave" sx={{ width: '6rem', fontSize: '1rem' }} />
                          ) : (
                            <Typography>
                              {accountVisible && saldo
                                ? (+saldo.SALDO_PARCEIRO).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                : '-----'}
                            </Typography>
                          )}
                        </Box>
                      </Col>

                      <Col xs={5}>
                        <Box mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={styles.textForm}>
                            <b>Data de Abertura:</b>
                          </Typography>
                          <Typography>
                            {accountVisible && accountVisible.DT_INSERT
                              ? moment(accountVisible.DT_INSERT).format('DD/MM/YYYY HH:mm:ss')
                              : '-----'}
                          </Typography>
                        </Box>
                      </Col>
                      
                      <Col xs={5}>
                        <Box mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={styles.textForm}>
                            <b>Saldo:</b>
                          </Typography>
                          {loading ? (
                            <Skeleton animation="wave" sx={{ width: '6rem', fontSize: '1rem' }} />
                          ) : (
                            <Typography>
                              {accountVisible && saldo
                                ? `R$${formatMoney(saldo.SALDO_ATUAL)}`
                                : '-----'}
                            </Typography>
                          )}
                        </Box>
                      </Col>

                      <Col xs={5}>
                        <Box mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={styles.textForm}>
                            <b>Data de Encerramento:</b>
                          </Typography>
                          <Typography>
                            {accountVisible && accountVisible.DT_ENCERRAMENTO
                              ? moment(accountVisible.DT_ENCERRAMENTO).format('DD/MM/YYYY HH:mm:ss')
                              : '-----'}
                          </Typography>
                        </Box>
                      </Col>

                      <Col xs={5}>
                        <Box mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={styles.textForm}>
                            <b>Saldo Bloqueado:</b>
                          </Typography>
                          {loading ? (
                            <Skeleton animation="wave" sx={{ width: '6rem', fontSize: '1rem' }} />
                          ) : (
                            <Typography>
                              {accountVisible && saldo
                                ? (+saldo.SALDO_BLOQUEADO).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                : '-----'}
                            </Typography>
                          )}
                        </Box>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <ReloadButtonStyle onClick={() => searchWithFilter()}>
                      <FiRefreshCw />
                    </ReloadButtonStyle>
                  </div>
                </div>
              </CardBody>
            </Card>
          ) : (
            <Loading color="blue" />
          )}
        </AccordionDetails>
      </Accordion>

      <ModalEnviarParaBaas
        toggle={() => setModalBaas({
          open: false,
          header: '',
          body: '',
          type: '',
          requestBody: {}
        })}
        {...modalBaas}
      />
    </>
  );
}

const styles = {
  textForm: {
    fontSize: '0.9em',
    color: '#8898aa',
    marginRight: '10px',
    marginBottom: '0',
  },
};
